import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/ServicesPage.css'; 
import ambulance from '../images/service/ambulance.png';
import delivered from '../images/service/delivered.png'
import doctor from '../images/service/doctor.png'
import wheelchair from '../images/service/wheelchair.png'
import elderly from '../images/service/elderly.png'
import nursing from '../images/service/nursing.png'
import old from '../images/service/old.png'
import transportation from '../images/service/transportation.png'
import stretcher from '../images/service/stretcher.png';
import treatment from '../images/service/treatment.png';
const ServicesPage = () => {
    const navigate = useNavigate();

    const handleBookingClick = () => {
        navigate('/schedule'); 
    };

    return (
        <div className="services-container">
            <h2>Transportation Services</h2>
            <br></br>
            <div className="services-grid">
                <div className="service-item">
                    <div className="service-icon">
                    <img src={wheelchair} alt="wheelchair" /></div>
                    <p>Wheelchair Transportation</p>
                </div>
                <div className="service-item">
                    <div className="service-icon"><img src={doctor} alt="doctor" /></div>
                    <p>Doctor's Appointments</p>
                </div>
                {/* <div className="service-item">
                    <div className="service-icon"> <img src={stretcher} alt="stretcher" /></div>
                    <p>Stretcher Transportation</p>
                </div> */}
                <div className="service-item">
                    <div className="service-icon"> <img src={elderly} alt="elderly" /></div>
                    <p>Elderly Transportation</p>
                </div>
               
            </div>
            <div className="services-grid">
                 <div className="service-item">
                    <div className="service-icon"><img src={nursing} alt="nursing" /></div>
                    <p>Nursing Home Appointments</p>
                </div>
                {/* <div className="service-item">
                    <div className="service-icon"><img src={delivered} alt="delivered" /></div>
                    <p>Bariatric Transportation</p>
                </div> */}
           
                <div className="service-item">
                    <div className="service-icon"> <img src={treatment} alt="treatment" /></div>
                    <p>Long Term Treatment</p>
                </div>
                {/* <div className="service-item">
                    <div className="service-icon"><img src={ambulance} alt="ambulance" /></div>
                    <p>Ambulatory Transportation</p>
                </div> */}
                <div className="service-item">
                    <div className="service-icon"><img src={transportation} alt="transportation" /></div>
                    <p>Patient Transportation</p>
                </div>
                {/* <div className="service-item">
                    <div className="service-icon"><img src={old} alt="old" /></div>
                    <p>Hospice Transportation</p>
                </div> */}
            </div><br></br>
            <button onClick={handleBookingClick} className="book-button">Book Medical Transportation</button>
        </div>
    );
};

export default ServicesPage;
