import React, { useState } from 'react';
import '../styles/SchedulePage.css'; 
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; 
import Spinner from './Spinner.js';

const SchedulePage = () => {
    const [submissionSuccess, setSubmissionSuccess] = useState(false); 
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        requestedBy: '',
        patientName: '',
        facilityName: '',
        phone: '',
        email: '',
        dateOfService: '',
        oneWayOrRoundTrip: 'One Way',
        typeOfTransportation: 'Wheelchair',
        oxygenNeeded: 'Yes',
        timeOfPickUpHH:'',
        timeOfPickUpMM:'',
        timeOfPickUpPeriod:'AM',
        timeOfAppointmentHH: '',
        timeOfAppointmentMM:'',
        timeOfAppointmentPeriod:'AM',
        pickUpAddress: '',
        destinationAddress: '',
        streetAddress1:'',
        streetAddress2:'',
        addressLine1:'',
        stateProvinceRegion1:'',
        addressLine2:'',
        stateProvinceRegion2:'',
        city1:'',
        city2:'',
        doctorName: '',
        patientWeight: '',
        oxygenPickUp: '',
        comments: ''
    });
    
    const handleDateChange = (date) => {
        setFormData({...formData, dateOfService: date});
    };

    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

   
    const handleSubmit = async (e) => {
        setSubmissionSuccess(false)
        setIsLoading(true);
        e.preventDefault();
        try {
            const response = await fetch('https://hilmeyserver-711ab2874635.herokuapp.com/submit-form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                setIsLoading(false);
                setSubmissionSuccess(true);
                console.log('Form data submitted successfully');
                
            } else {
                throw new Error('Form submission failed');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            
        }
    };

    

    return (
        <div className="schedule-form-container">
            {isLoading && <div className="spinner-container"><Spinner /></div>}
            <form className="schedule-form" onSubmit={handleSubmit}>
                <h2>Book Transportation</h2>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="requestedBy">Requested by (Name)*</label>
                        <input type="text" id="requestedBy" name="requestedBy" value={formData.requestedBy} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="patientName">Patient's Name*</label>
                        <input type="text" id="patientName" name="patientName" value={formData.patientName} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Facility Name and Room Number? (if applicable)</label>
                        <input type="text" id="facilityName" name="facilityName" value={formData.facilityName} onChange={handleChange} required />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="phone">Phone*</label>
                        <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                    <label htmlFor="dateOfService">Date of Service*</label>
                    <DatePicker
                        selected={formData.dateOfService}
                        onChange={handleDateChange}
                        dateFormat="MMMM d, yyyy"
                        className="form-control"
                    />
                </div>
                </div>
                <div className="form-row">
                    
                    
                </div>
            
                <div className="form-row">
                <div className="form-group">
                        <label htmlFor="oneWayOrRoundTrip">One Way or Round Trip?*</label>
                        <select id="oneWayOrRoundTrip" name="oneWayOrRoundTrip" value={formData.oneWayOrRoundTrip} onChange={handleChange} required>
                            <option value="Wheelchair">One Way</option>
                            <option value="Stretcher">Round Trip</option>
                       
                            
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="typeOfTransportation">Type of Transportation*</label>
                        <select id="typeOfTransportation" name="typeOfTransportation" value={formData.typeOfTransportation} onChange={handleChange} required>
                            <option value="Wheelchair">Wheelchair</option>
                            <option value="Stretcher">Stretcher</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="oxygenNeeded">Oxygen?*</label>
                        <select id="oxygenNeeded" name="oxygenNeeded" value={formData.oxygenNeeded} onChange={handleChange} required>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                </div>
                <div className="form-row">
                <div className="form-group">
                    <label htmlFor="timeOfPickUpHH">Time of Pick Up*</label>
                    <div className="time-input">
                        <input type="text" id="timeOfPickUpHH" name="timeOfPickUpHH" placeholder="HH" value={formData.timeOfPickUpHH} onChange={handleChange} required maxLength="2" />
                        <span>:</span>
                        <input type="text" name="timeOfPickUpMM" placeholder="MM" value={formData.timeOfPickUpMM} onChange={handleChange} required maxLength="2" />
                        <select name="timeOfPickUpPeriod" value={formData.timeOfPickUpPeriod} onChange={handleChange}>
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                        </select>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="timeOfAppointmentHH">Time of Appointment*</label>
                    <div className="time-input">
                        <input type="text" id="timeOfAppointmentHH" name="timeOfAppointmentHH" placeholder="HH" value={formData.timeOfAppointmentHH} onChange={handleChange} required maxLength="2" />
                        <span>:</span>
                        <input type="text" name="timeOfAppointmentMM" placeholder="MM" value={formData.timeOfAppointmentMM} onChange={handleChange} required maxLength="2" />
                        <select name="timeOfAppointmentPeriod" value={formData.timeOfPickUpPeriod} onChange={handleChange}>
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                        </select>
                    </div>
                </div>
                </div>
                <div className="form-row">
                    <div  className="form-group">
                        <label htmlFor="pickupAddress">Pick Up Address</label>
                        <input style ={{width:'98%'}}  type="text" id="pickupAddress" name="pickupAddress" value={formData.pickupAddress} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="destinationAddress">Destination Address</label>
                        <input style ={{width:'98%'}}  type="text" id="destinationAddress" name="destinationAddress" value={formData.destinationAddress} onChange={handleChange} required />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="streetAddress1">Street Address</label>
                        <input style ={{width:'98%'}} type="text" id="streetAddress1" name="streetAddress1" value={formData.streetAddress1} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="streetAddress2">Street Address</label>
                        <input style ={{width:'98%'}} type="text" id="streetAddress2" name="streetAddress2" value={formData.streetAddress2} onChange={handleChange} required />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="addressLine1">Address Line 1</label>
                        <input style ={{width:'98%'}} type="text" id="addressLine1" name="addressLine1" value={formData.addressLine1} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="stateProvinceRegion1">State/Province/Region</label>
                        <input type="text" id="stateProvinceRegion1" name="stateProvinceRegion1" value={formData.stateProvinceRegion1} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="addressLine2">Address Line 2</label>
                        <input style ={{width:'98%'}} type="text" id="addressLine2" name="addressLine2" value={formData.addressLine2} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="stateProvinceRegion2">State/Province/Region</label>
                        <input style ={{width:'98%'}} type="text" id="stateProvinceRegion2" name="stateProvinceRegion2" value={formData.stateProvinceRegion2} onChange={handleChange} required />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="city1">City</label>
                        <input style ={{width:'fit-content'}}  placeholder='ZIP/Postal Code' type="text" id="city1" name="city1" value={formData.city1} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="city2">City</label>
                        <input style ={{width:'fit-content'}}  placeholder='ZIP/Postal Code' type="text" id="city2" name="city2" value={formData.city2} onChange={handleChange} required />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="doctorName">Name of Doctor</label>
                        <input style ={{width:'98%'}} type="text" id="doctorName" name="doctorName" value={formData.doctorName} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="patientWeight">Patient's Weight</label>
                        <input style ={{width:'98%'}} type="text" id="patientWeight" name="patientWeight" value={formData.patientWeight} onChange={handleChange} required />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label id="largeLabel" htmlFor="oxygenPickup">If Yes, do we need to arrange a separate pick up for oxygen? Where?</label>
                        <input style ={{width:'98%', height:'60%'}} type="text" id="oxygenPickup" name="oxygenPickup" value={formData.oxygenPickup} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="comments">Comments</label>
                        <input style ={{width:'98%', height:'60%'}} type="text" id="comments" name="comments" value={formData.comments} onChange={handleChange} required />
                    </div>
                </div>
                <button type="submit">Submit</button>
                {submissionSuccess && <PopupMessage />}
            </form>
            
           
        </div>
        
    );
};

export default SchedulePage;
const PopupMessage = () => (
    <div className="popup-message">
        <p>Form submission successful!</p>
    </div>
);
