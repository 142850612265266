import React, {useState} from 'react';
import '../styles/ContactUsPage.css';
import { Container } from '@mui/material';
import Spinner from './Spinner.js';
const ContactUsPage = () => {
    const [submissionSuccess, setSubmissionSuccess] = useState(false); 
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        setSubmissionSuccess(false)
        setIsLoading(true);
        e.preventDefault();
        try {
            const response = await fetch('https://hilmeyserver-711ab2874635.herokuapp.com/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                setIsLoading(false);
                setSubmissionSuccess(true);
                // console.log('Form submitted successfully');
                
            } else {
                console.error('Form submission failed');
                
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            
        }
    };

    return (
        <Container className='container'> 
        {isLoading && <div className="spinner-container"><Spinner /></div>}
          <div className="contact-form-section">
                <h2>Ask A Question</h2>
                <form onSubmit={handleSubmit}>
                    <input type="text" name="name" placeholder="Your Name (required)" required value={formData.name} onChange={handleChange} />
                    <input type="email" name="email" placeholder="Your Email (required)" required value={formData.email} onChange={handleChange} />
                    <input type="tel" name="phone" placeholder="Phone Number (required)" required value={formData.phone} onChange={handleChange} />
                    <textarea name="message" placeholder="Your Message" value={formData.message} onChange={handleChange}></textarea>
                    <button type="submit">Send</button>
                </form>
                {submissionSuccess && <PopupMessage />} 
            </div>
      
        </Container>
    );
};

export default ContactUsPage;

const PopupMessage = () => (
    <div className="popup-message">
        <p>Form submission successful!</p>
    </div>
);

