import React from 'react';
import '../styles/AboutUs.css';
import circleimage from"../images/IMG_2268.JPEG";
import shapedimage from"../images/IMG_2267.JPG";
import barcode from"../images/image.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faGlobe, faMapMarked } from '@fortawesome/free-solid-svg-icons';


const AboutUs = () => {
    return(
    <><div className="main-page">
            <div className="title-section">
                <h1 className='paragraph'>HILMEY NON-EMERGENCY MEDICAL TRANSPORTATION</h1>
                <h2 className='paragraph'>YOUR SAFETY MATTERS!</h2>
                <div className="shaped-image">
                        <img src={shapedimage} alt="Service Highlight" />
                    </div>
            </div> 
            <div className="content-section">
                <div className="text-and-image">
                    <p className='paragraph'>Hilmey transportation, your trusted non-medical transportation service in Jacksonville, Florida. 
                        At Hilmey transportation, we pride ourselves on delivering reliable, safe, and personalized transportation solutions tailored to meet your unique needs.
                         With a commitment to punctuality and professional service, our team of dedicated drivers ensures you reach your destination comfortably and on time. 
                         Discover the convenience and peace of mind that comes with choosing Hilmey transportation for all your transportation requirements in the vibrant city of Jacksonville and its neighbor cities.</p>
                    
                    <div className="circle-image">
                        <img src={circleimage} alt="Service Highlight" />
                    </div>
                </div>
            </div>
              
            <div className="bottom-sections">
                <div className="choose-us-section">
                    <h3 >CHOOSE US FOR:</h3>
                    <ul>
                        <li>Wheelchair Transportation.</li>
                        <li>Nursing Home Transportation</li>
                        <li>Long Term Treatments.</li>
                        <li>Dialysis Appointment.</li>
                        <li>Chemotherapy Transportation.</li>
                        <li>Doctor Appointment.</li>
                        <li>Elderly Transportation.</li>
                    </ul>
                </div>
             
                {/* </div> */}
                <div className="barcode">
                    <div className="contact-section">
                        <div className="more-info" aria-hidden="true">MORE INFORMATION</div>
                        <div className="contact-icons">
                            <div className="icon"><FontAwesomeIcon icon={faPhone} /> ((904) 477-7207 )</div>
                            <div className="icon"><FontAwesomeIcon icon={faEnvelope} />Hilmeytransportation@outlook.com</div>
                            <div className="icon"><FontAwesomeIcon icon={faGlobe} /> www.hilmeytransportation.com</div>
                            <div className="icon"><FontAwesomeIcon icon={faMapMarked} /> Jacksonville,FL</div>
                        </div>

                        <img src={barcode} alt="Barcode" />
                    </div>
                </div>
            </div>
        </div>
       
        </>
);
};

export default AboutUs;
