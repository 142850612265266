import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../images/IMG_2806.jpg';
import '../styles/Header.css';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    let navigate = useNavigate(); 

    const goToPage = () => {
        navigate('/schedule');
    };
    const menuItems = [
        { text: 'Home', path: '/' },
        { text: 'Services', path: '/services' },
        { text: 'About Us', path: '/about' },
        { text: 'Contact Us', path: '/contact' },
        { text: 'Schedule', path: '/schedule' },
    ];

    return (
        <div className="header">
            <div className='navBar'>
                <AppBar position="static" style={{ backgroundColor: '#000000', color: '#f1c40f' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                    <List>
                        {menuItems.map((item, index) => (
                            <ListItem button key={index} onClick={toggleDrawer(false)} component={Link} to={item.path}>
                                <ListItemText primary={item.text} style={{ color: '#f1c40f' }} />
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
            </div>
            <div className="logo">
                <img src={logo} alt="Hilmey Non-Emergency Transport Logo" />
            </div>
            <div className="contact-info">
            Call Us Today: (904) 477-7207 | Email: Hilmeytransportation@outlook.com
            </div>
            <nav>
                <ul className="navigation">
                    <li><Link to="/">HOME</Link></li>
                    <li><Link to="/services">OUR SERVICES</Link></li>
                    <li><Link to="/about">ABOUT US</Link></li>
                    <li><Link to="/contact">CONTACT US</Link></li>
                    <li><button onClick={goToPage}>BOOK TRANSPORT</button></li>
                </ul>
            </nav>
        </div>
    );
};

export default Header;
