import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';
import Header from './components/Header';
import HomePage from './components/HomePage';
import ServicesPage from './components/ServicesPage';
import ContactUsPage from './components/ContactUsPage';
import SchedulePage from './components/SchedulePage';
import AboutUs from './components/AboutUs';
function App() {
  return (
      <Router>
          <Header />
          <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/services" element={<ServicesPage />} />
              <Route path="/about" element={<AboutUs/>} />
              <Route path="/contact" element={<ContactUsPage />} />
              <Route path="/schedule" element={<SchedulePage />} />
          </Routes>
      </Router>
  );
}
  
  export default App;




